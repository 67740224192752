const  router = [
	{
		path: "/setting/layout",
		component: () => import('../../../pages/setting/Layout.vue'),
		meta: {title: '设置'},
		name: 'setting_layout',
		children:[
			{
				path: "/setting",
				component: () => import('../../../pages/setting/Index.vue'),
				meta: {title: '店铺资料'},
				name: 'setting_index',
			},
			{
				path: "/setting/webhook",
				component: () => import('../../../pages/setting/Webhook.vue'),
				meta: {title: '企微通知'},
				name: 'setting_webhook',
			},
			{
				path: "/mendian",
				component: () => import('../../../pages/setting/Mendian.vue'),
				meta: {title: '门店管理'},
				name: 'setting_mendian',
			},
			{
				path: "/mendian_details",
				component: () => import('../../../pages/setting/components/mendian/gwDEetails.vue'),
				meta: {title: '门店详情'},
				name: 'mendian_details',
			},
			{
				path: "/account",
				component: () => import('../../../pages/setting/Account.vue'),
				meta: {title: '帐号管理'},
				name: 'setting_account',
			},
			{
				path: "/setting/sms",
				component: () => import('../../../pages/setting/Sms.vue'),
				meta: {title: '短信设置'},
				name: 'setting_sms',
			},
			{
				path: "/setting/integral",
				component: () => import('../../../pages/setting/Integral.vue'),
				meta: {title: '积分设置'},
				name: 'setting_integral',
			},
			{
				path: "/setting/balance",
				component: () => import('../../../pages/setting/Balance.vue'),
				meta: {title: '余额设置'},
				name: 'setting_balance',
			},
			{
				path: "/setting/buy",
				component: () => import('../../../pages/setting/Buy.vue'),
				meta: {title: '买单设置'},
				name: 'setting_buy',
			},
			{
				path: "/setting/printer",
				component: () => import('../../../pages/setting/Printer.vue'),
				meta: {title: '打印机设置'},
				name: 'setting_printer',
			},
			{
				path: "/setting/vip",
				component: () => import('../../../pages/setting/vip.vue'),
				meta: {title: '会员权益'},
				name: 'vip',
			},
			{
				path: "/setting/bank",
				component: () => import('../../../pages/bank/index.vue'),
				meta: {title: '提现申请'},
				name: 'setting_bank',
			},
			{
				path: "/setting/join",
				component: () => import('../../../pages/joinus/index.vue'),
				meta: {title: '加入我们'},
				name: 'setting_join',
			},
		],
	}
];

export default router