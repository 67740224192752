const  router = [
	{
		path: "/mall/layout",
		component: () => import('../../../pages/mall/Layout.vue'),
		meta: {title: '商城'},
		name: 'mall_layout',
		children:[
			// {
			// 	path: "/mall",
			// 	component: () => import('../../../pages/mall/Index.vue'),
			// 	meta: {title: '商品分类'},
			// 	name: 'mall_type'
			// },
			{
				path: "/mall",
				// path: "/mall/manageGoods",
				component: () => import('../../../pages/mall/ManageGoods.vue'),
				meta: {title: '商品管理'},
				name: 'mall_manage_goods'
			},
			{
				path: "/mall/editGoods",
				component: () => import('../../../pages/mall/EditGoods.vue'),
				meta: {title: '编辑商品'},
				name: 'mall_manage_goods_edit'
			},
			{
				path: "/mall/flashSale",
				component: () => import('../../../pages/mall/FlashSale.vue'),
				meta: {title: '限时秒杀'},
				name: 'mall_flash_sale'
			},
			{
				path: "/mall/editFlashSale",
				component: () => import('../../../pages/mall/EditFlashSale.vue'),
				meta: {title: '编辑秒杀商品'},
				name: 'mall_flash_sale_edit'
			},
			{
				path: "/mall/pinTuan",
				component: () => import('../../../pages/mall/PinTuan.vue'),
				meta: {title: '多人拼团'},
				name: 'mall_pin_tuan'
			},
			{
				path: "/mall/editPinTuanGoods",
				component: () => import('../../../pages/mall/EditPinTuanGoods.vue'),
				meta: {title: '编辑拼团商品'},
				name: 'mall_pin_tuan_edit_goods'
			},
			{
				path: "/mall/store",
				component: () => import('../../../pages/mall/Store.vue'),
				meta: {title: '囤货管理'},
				name: 'mall_store'
			},
			{
				path: "/mall/editStoreGoods",
				component: () => import('../../../pages/mall/EditStoreGoods.vue'),
				meta: {title: '添加囤货管理'},
				name: 'mall_store_goods_edit'
			},
			{
				path: "/mall/storeOrderDetail",
				component: () => import('../../../pages/mall/StoreOrderDetail.vue'),
				meta: {title: '囤货订单详情'},
				name: 'mall_store_order_detail'
			},
			{
				path: "/mall/order",
				component: () => import('../../../pages/mall/Order.vue'),
				meta: {title: '订单管理'},
				name: 'mall_goods_order'
			},
			{
				path: "/mall/order/detail",
				component: () => import('../../../pages/mall/OrderDetail.vue'),
				meta: {title: '订单详情'},
				name: 'mall_goods_order_detail'
			},
			{
				path: "/mall/freight/template",
				component: () => import('../../../pages/mall/FreightTemplate.vue'),
				meta: {title: '运费模板'},
				name: 'mall_freight_template'
			},
			{
				path: "/mall/customerToOrder",
				component: () => import('../../../pages/mall/CustomerToOrder.vue'),
				meta: {title: '代客下单'},
				name: 'mall_customerToOrder'
			},
			{
				path: "/mall/statistics",
				component: () => import('../../../pages/mall/Statistics.vue'),
				meta: {title: '数据统计'},
				name: 'mall_statistics'
			},
		],
	}
];

export default router