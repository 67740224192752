const router = [

	{
		path: '/index',
		// component: () => import('../../../pages/dashboard/Index.vue'),
		component:()=>import('@/pages/dashboard/Index.vue'),
		meta: {title: '控制台'},
		name: 'homeIndex'
	},
	{
		path: '/table',
		component: () => import('../../../pages/dashboard/Table.vue'),
		meta: {title: '数据明细'},
		name: 'homeTable'
	}
];

export default router