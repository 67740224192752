const  router = [
	{
		path: "/member/layout",
		component: () => import('../../../pages/member/Layout.vue'),
		meta: {title: '会员管理'},
		name: 'member_layout',
		children:[
			{
				path: "/member",
				component: () => import('../../../pages/member/Index.vue'),
				meta: {title: '会员管理'},
				name: 'member_index',
			},
			{
				path: "/member/rank",
				component: () => import('../../../pages/member/Rank.vue'),
				meta: {title: '会员等级'},
				name: 'member_rank',
			},
			{
				path: "/member/integral",
				component: () => import('../../../pages/member/Integral.vue'),
				meta: {title: '会员积分'},
				name: 'member_integral',
			},
			{
				path: "/member/money",
				component: () => import('../../../pages/member/Money.vue'),
				meta: {title: '会员余额'},
				name: 'member_money',
			},
			{
				path: "/member/detail",
				component: () => import('../../../pages/member/Detail.vue'),
				meta: {title: '会员详情'},
				name: 'member_index_detail',
			},


			{
				path: "/member/chongzhi",
				component: () => import('../../../pages/member/chongzhi.vue'),
				meta: {title: '充值记录'},
				name: 'member_chongzhi',
			},
			{
				path: "/member/xiaofei",
				component: () => import('../../../pages/member/xiaofei.vue'),
				meta: {title: '消费记录'},
				name: 'member_xiaofei',
			},
			{
				path: "/member/washorder",
				component: () => import('../../../pages/member/washorder.vue'),
				meta: {title: '消费记录'},
				name: 'member_washorder',
			},
			{
				path: "/member/supplement",
				component: () => import('../../../pages/member/Supplement.vue'),
				meta: {title: '线下补单'},
				name: 'member_supplement',
			},
			{
				path: "/member/buy",
				component: () => import('../../../pages/member/Buy.vue'),
				meta: {title: '买单记录'},
				name: 'member_buy',
			},
		]
	}
];

export default router