const  router = [
	{
		path: "/project/layout",
		component: () => import('../../../pages/project/Layout.vue'),
		meta: {title: '项目'},
		name: 'project_layout',
		children:[
			{
				path: "/project",
				component: () => import('../../../pages/project/Index.vue'),
				meta: {title: '项目管理'},
				name: 'project_index'
			},
			{
				path: "/project/edit",
				component: () => import('../../../pages/project/EditProject.vue'),
				meta: {title: '编辑项目'},
				name: 'project_index_edit'
			},
			{
				path: "/project/snapup",
				component: () => import('../../../pages/project/Snapup.vue'),
				meta: {title: '抢购管理'},
				name: 'project_snapup'
			},
			{
				path: "/project/snapup/edit",
				component: () => import('../../../pages/project/EditSnapup.vue'),
				meta: {title: '编辑抢购'},
				name: 'project_snapup_edit'
			},
			{
				path: "/project/oncecard",
				component: () => import('../../../pages/project/Oncecard.vue'),
				meta: {title: '次卡管理'},
				name: 'project_oncecard'
			},
			{
				path: "/project/oncecard/edit",
				component: () => import('../../../pages/project/EditOncecard.vue'),
				meta: {title: '编辑次卡'},
				name: 'project_oncecard_edit'
			},
			{
				path: "/project/orderOncecard",
				component: () => import('../../../pages/project/OrderOncecard.vue'),
				meta: {title: '次卡订单'},
				name: 'project_orderOncecard'
			},
			{
				path: "/project/orderOncecardDetail",
				component: () => import('../../../pages/project/OrderOncecardDetail.vue'),
				meta: {title: '次卡订单详情'},
				name: 'project_orderOncecard_detail'
			},
			{
				path: "/project/orderSnapup",
				component: () => import('../../../pages/project/OrderSnapup.vue'),
				meta: {title: '抢购订单'},
				name: 'project_orderSnapup'
			},
			{
				path: "/project/orderSnapupDetail",
				component: () => import('../../../pages/project/OrderSnapupDetail.vue'),
				meta: {title: '抢购订单详情'},
				name: 'project_orderSnapup_detail'
			},
			{
				path: "/project/yuyue",
				component: () => import('../../../pages/project/YuyueProject.vue'),
				meta: {title: '预约设置'},
				name: 'project_yuyue'
			},
			{
				path: "/project/yyorder",
				component: () => import('../../../pages/project/YuyueOrder.vue'),
				meta: {title: '预约列表'},
				name: 'project_yyorder'
			},
			{
				path: "/project/addyyorder",
				component: () => import('../../../pages/project/addYyOrder.vue'),
				meta: {title: '新增预约'},
				name: 'project_yyorder_add'
			},
			{
				path: "/project/yuyue/form",
				component: () => import('../../../pages/project/YuyueForm.vue'),
				meta: {title: '预约类型表单'},
				name: 'project_yuyue_form'
			},
		],
	}
];

export default router